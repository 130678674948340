import * as React from "react";
import { graphql } from "gatsby";

import { Query } from "@graphql-types";
import { P } from "@util/standard";
import CollectionTemplate from "@shared/collectionTemplate";
import { BreadcrumbPageContext } from "@util/types";

interface NewPageContext extends BreadcrumbPageContext {
  collection: string;
}
interface Props {
  data: Query;
  pageContext: NewPageContext;
}

const CollectionPage = (props: Props) => {
  const { data, pageContext } = props;
  const category = data.sanityCategory;

  if (!category) return null;

  const products =
    pageContext.iban == "nz" ? category.products : category.productsAu;

  if (!category) {
    return <P color="errorRed">Error loading Page</P>;
  }

  const isBundle =
    (category.bundleProducts && category.bundleProducts.length > 0) ?? false;

  return (
    <CollectionTemplate
      title={category.main?.title}
      categoryList={data.allSanityCategory.nodes}
      hero={category.main?.hero ? category.main?.hero : null}
      productList={products}
      bundleList={category.bundleProducts}
      isBundle={isBundle}
      selectedCollection={pageContext.collection ?? ""}
      selectedCategory={category.main?.slug?.current ?? ""}
      selectedCategoryName={category.main?.title ?? ""}
      seo={category.meta}
      slug={category.main?.slug?.current}
      pageContext={pageContext}
      iban={pageContext.iban}
      collectionList={data.allSanityCollection.nodes}
      categoryColour={category?.categoryColour?.value}
    />
  );
};

export default CollectionPage;

export const query = graphql`
  query CategoryPageQuery($slug: String, $iban: String) {
    sanityCategory(
      main: {
        slug: { current: { eq: $slug } }
        regions: { elemMatch: { iban: { eq: $iban } } }
      }
    ) {
      meta {
        ...sanitySeo
      }
      ...sanityCategoryPreview
    }
    allSanityCategory(
      filter: { main: { regions: { elemMatch: { iban: { eq: $iban } } } } }
    ) {
      nodes {
        meta {
          ...sanitySeo
        }
        ...sanityCategoryPreview
      }
    }
    allSanityCollection(
      sort: { fields: orderRank, order: ASC }
      filter: { main: { region: { iban: { eq: $iban } } } }
    ) {
      nodes {
        id
        _key
        ...sanityCollectionPreview
      }
    }
  }
`;
